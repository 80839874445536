import { DateTime } from "luxon";
import dayjs from "dayjs";
import { columnsTotalWidthSelector } from "@material-ui/data-grid";
import { stringify } from "querystring";

export const resolveMockup = <T,>(data: T) => {
  let r: Promise<T> = new Promise((resolve) => {
    return resolve(data as T);
  });
  return r;
};

export const dateStr = (date: Date) => {
  if (!date) {
    return "";
  }
  // console.log(date);
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_MED);
};

export const dateStrAPI = (date: Date | string | null) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format("YYYY-MM-DD");
};

export const dateStrAPIFormat = (date: Date | string | null) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format("DD/MM/YYYY");
};

export const timeStr = (date: Date | string | null) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format("h:mm A");
};

export const DateString = (date: Date | string | null) => {
  if (!date) {
    return null;
  }
  return dayjs(date).toISOString().split('T')[0];
};

export const DateStringAPI = (date: Date) => {
  if (!date) {
    return null as any;
  }
  return dayjs(date).toISOString().split('T')[0];
};

export const DateTimeString = (date: Date| String | null) => {
  if(!date) {
    return null;
  }
  let splitDate;
  if(date){
    var StringDate = date.toString();
    splitDate = StringDate.split('Z')[0];
  }
  return dayjs(splitDate).format("YYYY-MM-DD") + " " + dayjs(splitDate).format("h:mm A");
}

export const DateAndTimeString = (date: Date| string | null) => {
  if(!date) {
    return null;
  }
  return dayjs(date).format("YYYY-MM-DD") + ", " + dayjs(date).format("h:mm A");
}

export const dateStrFormat = (date: Date) => {
  if (!date) {
    return null as any;
  }
  let splitDate;
  if(date){
    var StringDate = date.toString();
    splitDate = StringDate.split('Z')[0];
  }
  return dayjs(splitDate).format("DD/MM/YYYY");
};

export const dateStrFormatLogin = (date: Date | string) => {
  if (!date) {
    return null as any;
  }
  let splitDate;
  if(date){
    var StringDate = date.toString();
    splitDate = StringDate.split('Z')[0];
  }
  return dayjs(splitDate).format("DD/MM/YYYY") + ", " + dayjs(splitDate).format("h:mm:ss A");
};

export const CalculateAge = (date: Date) =>{
  if(!date){
    return null;
  }
  let birthDate = new Date(date);
  let difference = Date.now() - birthDate.getTime();
  
  let ageDate = new Date(difference);
  let calculatedAge = Math.abs(ageDate.getUTCFullYear() -1970);
  return calculatedAge;
}

export const dateStrFormatBuddhist = (date: Date | String) => {
  if (!date) {
    return null as any;
  }
  let splitDate;
  if(date){
    var StringDate = date.toString();
    splitDate = StringDate.split('Z')[0];
  }
  let dateFormat = dayjs(splitDate).format("DD/MM/YYYY");
  let splitDateFormat;
  let returnDate = "";
  if(dateFormat){
    splitDateFormat= dateFormat.split("/");
    let changeYear = parseInt(splitDateFormat[2]) + 543 ;
    returnDate = splitDateFormat[0] + "/" + splitDateFormat[1] + "/" + changeYear ;
  }
  return returnDate;
};

export const dateString  = (date: Date | string | null) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format("DD MMM, YYYY");
};

export const dateStringFullMothName  = (date: Date | string | null) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format("DD MMMM, YYYY");
};

export const dateStrFormatTime = (date: Date | string | null) => {
  if (!date) {
    return null as any;
  }
  let splitDate;
  if(date){
    var StringDate = date.toString();
    splitDate = StringDate.split('Z')[0];
  }
  return dayjs(date).format("h:mm A");
};

export const timeString = (date: Date | string | null) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format("HH:mm");
};

export const dateStringAPI = (date: Date | string | null) => {
  if (!date) {
    return "";
  }
  return dayjs(date).format("YYYY-MM-DD");
};

// function fnCalculateAge(){

//   var userDateinput = document.getElementById("txtDOB").value;  
// console.log(userDateinput);

//   // convert user input value into date object
// var birthDate = new Date(userDateinput);
//  console.log(" birthDate"+ birthDate);

// // get difference from current date;
// var difference=Date.now() - birthDate.getTime(); 
   
// var  ageDate = new Date(difference); 
// var calculatedAge=   Math.abs(ageDate.getUTCFullYear() - 1970);
// alert(calculatedAge);
// }
