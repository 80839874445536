export const API_PATH =
    // window.location.protocol + '//' + 'quarantine.pmhsolution.com';
  window.location.protocol + "//" + "staging.pmhsolution.com";

  export const API_PATH_GO = "https://api-navamin9.space-trax.com";
  // "https://api.space-trax.com"; //
  // console.log(API_PATH);

  export const API_PATH_TELEMED =
  "https://api-navamin9.space-trax.com"; // new domain
  // console.log(window.location.protocol)
// if (window.location.protocol === "https:") {
//   window.location.replace(
//     `http:${window.location.href.substring(window.location.protocol.length)}`
//   );
// }

//testing