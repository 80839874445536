import { useEffect } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    TextField,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Modal
  } from "@material-ui/core";
  import _ from "lodash";
  import { observer } from "mobx-react-lite";
  import React, { useState } from "react";
  import { useHistory } from "react-router-dom";
  import { VariantType, useSnackbar } from "notistack";
  import { useRequestOTP } from "../services/api/auth/useRequestOTP";
  import { useVerifyOTP } from "../services/api/auth/useVerifyOTP";
  import ArrowBackIcon from "@material-ui/icons/ArrowBack";
  import logo from "../components/assets/logo-PMH.svg";
  import Logo from '../components/assets/Logo_Navamin_1.png';
  import axios from "axios";
  import { columnsTotalWidthSelector } from "@material-ui/data-grid";
import { css, jsx } from "@emotion/react";
import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
import LuxonUtils from "@date-io/luxon";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";   
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CloseIcon from "@material-ui/icons/Close";

import { GetPolicy,GetSecretCode,VerifyOtp } from "../services/api/webConsent/WebConsentService";
import validator from 'validator';
import { dateStringAPI } from "../utils";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import '../services/api/webConsent/style.css';

const OtpTextField = ({ index, form, setForm }: any) => {
  const onKeydown = (e: any) => {
    console.log(e.code, e.target.value);
    if (
      e.code === "Backspace" &&
      (!e.target.value || e.target.value.length === 0)
    ) {
      let cur = parseInt(e.target.name.replace("number", ""));
      let focus = "number" + (cur - 1);
      if (document.getElementsByName(focus)[0]) {
        document.getElementsByName(focus)[0].focus();
      }
    }
  };
  const onChange = (e: any) => {
    if (e.target.name) {
      let newV = { ...form };
      let cur = parseInt(e.target.name.replace("number", ""));
      let focusNext = e.target.value !== null && e.target.value.length > 0;
      let next = "number" + (cur + 1);
      let prev = "number" + (cur - 1);
      let focus = focusNext ? next : prev;
      if (e.target.value.toString()[1]) {
        newV[next] = e.target.value.toString()[1];
      }
      newV[e.target.name] = e.target.value.toString()[0];

      setForm(newV);
      if (document.getElementsByName(focus)[0]) {
        document.getElementsByName(focus)[0].focus();
      }
    }
  };
  return (
    <Grid item xs={2}>
      <TextField
        autoFocus={index === 0}
        inputProps={{
          min: 0,
          style: { textAlign: "center" },
        }}
        onChange={onChange}
        onKeyDown={onKeydown}
        value={form["number" + index] || ""}
        name={"number" + index}
        variant="outlined"
      />
    </Grid>
  );
};


const OtpModal = observer(
  ({
    isOtpModal, 
    onClick,
    setIsOtpModal,
    buttonName = "Submit",
    otpCode,
    setOtpCode,
    otpResponseData
  }: {
    isOtpModal: boolean;
    setIsOtpModal: any;
    onClick?: any;
    buttonName?: string;
    otpCode : any;
    setOtpCode: any;
    otpResponseData : any;
  }) => {

    const [form, setForm] = useState<any>({});
    const { enqueueSnackbar } = useSnackbar();
    const useStyles = makeStyles({
      root: {
        width: "40%",
        backgroundColor: "#303f9f",
      },
      label: {
        color: "#fff",
      },
    });
    const btnStyles = useStyles();
    const { fire_verifyOtp, loading_verifyOTP } = VerifyOtp();

    return (
      <Modal
        open={isOtpModal}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 600, borderRadius: 20, border: 'solid 2px #3f51b5' }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            mt={1}
          >
            <Box css={{ color: "#4B4C56" }}>
              <h3>Please fill OTP code.</h3> 
            </Box>
            <Box
              onClick={() => {
                setIsOtpModal(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box px={{ md: 8, xs: 0 }} mt={4}>
          <Grid container direction="row" spacing={1}>
              {Array.from(Array(6).keys()).map((_v, index) => (
                <OtpTextField index={index} form={form} setForm={setForm} />
              ))}
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              disabled={
                !_.every(Array.from(Array(6).keys()), (_v, i) => {
                  return form["number" + i] && form["number" + i].length > 0;
                })
              }
              onClick={async() =>{
                let otp = "";
                for(let i = 0; i< 6 ; i++){
                  let index = "number" + i; 
                  otp = otp + form[index];
                }
                console.log("otp:", otp);
                setOtpCode(otp);
                let inputData ={
                  username: otpResponseData.userName,
                  otp: otp,
                  ref_code: otpResponseData.ref_code,
                  otp_type:6,
                  secret_code: otpResponseData.secret_code,
                  secret_type:7,
                  user_id: otpResponseData.user_id
                };
                try {
                  fire_verifyOtp(inputData)
                    .then(
                      () => {
                        enqueueSnackbar("Success", {
                          variant: "success",
                          autoHideDuration: 3000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        });
                        setIsOtpModal(false);
                        window.location.reload();
                    })
                    .catch((ex) => {
                      enqueueSnackbar(
                          "Failed to get OTP code" +
                            (ex ? " : " + ex.toString() : ""),
                          {
                            variant: "error",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                          }
                        ); 
                        setIsOtpModal(false);       
                    });
                } catch (ex) {}
                
              }}
              size="medium"
              variant="contained"
              classes={{
                root: btnStyles.root,
                label: btnStyles.label,
              }}
            >
              {buttonName}
            </Button>
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

  export const WebConsent = observer(() => {
    
    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [ agree, setAgree ] = useState(true);
    const [ disagree, setDisagree ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ name, setName ] = useState('');
    const [ lastname, setLastName ] = useState('');
    const [ age, setAge ] = useState('');
    const [ dateOfBirth, setDateofBirth ] = useState<Date | null>(null);
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ policyId, setPolicyId ] = useState("");
    const [ emailErrorText, setEmailErrorText ] = useState('');
    const { result_getPolicy, loading_getPolicy } = GetPolicy();
    const { result_getSecretCode, fire_getSecretCode, loading_getSecretCode } = GetSecretCode();
    const [ otpCode,setOtpCode] = useState();
    const [ isOtpModal, setIsOtpModal ] = useState(false);
    const [ otpResponseData, setOtpResponseData ] = useState<any>();

    const handleChangeAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgree(event.target.checked);
        setDisagree(!(event.target.checked));
    };

    const handleChangeDisagree = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisagree(event.target.checked);
        setAgree(!(event.target.checked));
    }

    const validateEmail = (e : React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        var checkEmail = e.target.value;
      
        if(email) {
            if (validator.isEmail(checkEmail)) {
                setEmailErrorText('');
                setEmail(checkEmail);
            } else {
                setEmailErrorText('Enter valid Email!')
            }
        }
    }

    useEffect( () => {
        if(result_getPolicy){
            setPolicyId(result_getPolicy?.id);
        }
        console.log("get policy:", result_getPolicy);
    }, [result_getPolicy]);

    return (
      <Box
        css={{ backgroundColor: "var(--primary-bg)" }}
        height= "100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* <img src={logo} css={{ position: "absolute", top: 20 }} /> */}
        <Container maxWidth="lg" style={{marginTop: '20px', marginBottom: '20px'}}>
          <Paper>
            <Box p={4}>
                <Box
                    style={{
                        width: "100%",
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                        marginTop: '10px'
                    }}
                >
                    <img src={Logo} css={{ alignItems: 'center', top: 20 }} /> 
                </Box>
                <br/>
                <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>หนังสือแสดงความยินยอมรับการรักษาเป็นผู้ป่วยนอก</p>
                <br/>
                <br/>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={2} lg={2}>
                        <TextField
                            label="ชื่อ"
                            value= {name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                            name="name"
                            fullWidth
                            margin="dense"
                            size="small"
                            style={{paddingRight: '10px' }}
                        />
                    </Grid> 
                    <Grid item xs={12} md={2} lg={2}>
                        <TextField
                            label="นามสกุล"
                            value= {lastname}
                            onChange={(event) => {
                                setLastName(event.target.value);
                            }}
                            name="lastname"
                            fullWidth
                            margin="dense"
                            size="small"
                            style={{paddingRight: '10px' }}
                        />
                    </Grid> 
                    <Grid item xs={12} md={2} lg={2}>
                        <TextField
                            label="อายุ"
                            value= {age}
                            type ='number'
                            onChange={(event) => {
                                setAge(event.target.value);
                            }}
                            name="age"
                            fullWidth
                            margin="dense"
                            size="small"
                            style={{paddingRight: '10px' }}
                        />
                    </Grid>  
                     
                    <Grid item xs={12} md={3} lg={3}>
                        
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <KeyboardDatePicker
                            style={{
                                width: "100%",
                                borderWidth: 0,
                                paddingRight: '30px'
                            }}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="dense"
                            id="dateOfBirth"
                            label="วัน/เดือน/ปีเกิด"
                            ToolbarComponent={(props: ToolbarComponentProps) => {
                                return (
                                  <div
                                    style={{
                                      background: "#3f51b5",
                                    }}
                                  >
                                    <Box p={2}>
                                      <Button
                                        size="small"
                                        style ={{
                                            fontSize : '0.8rem',
                                            color: 'white',
                                            lineHeight: '1rem',
                                            fontWeight: 'bold',
                                            textTransform: 'none'
                                        }}
                                        onClick={() => {
                                          props.setOpenView("date");
                                        }}
                                      >
                                        {props.date?.weekdayLong + " " + props.date?.day}
                                      </Button>
                                      <Grid>
                                        <Button
                                          size="small"
                                          style ={{
                                            fontSize : '1.5rem',
                                            color: 'white',
                                            lineHeight: '1.65rem',
                                            fontWeight: 'bold',
                                            textTransform: 'none'
                                        }}
                                          onClick={() => {
                                            props.setOpenView("month");
                                          }}
                                        >
                                          {props.date?.monthLong}
                                        </Button>
                                        <Button
                                          size="small"
                                          style ={{
                                            fontSize : '1.5rem',
                                            color: 'white',
                                            lineHeight: '1.65rem',
                                            fontWeight: 'bold',
                                            textTransform: 'none'
                                        }}
                                          onClick={() => {
                                            props.setOpenView("year");
                                          }}
                                        >
                                          {props.date?.year}
                                        </Button>
                                      </Grid>
                                    </Box>
                                  </div>
                                );
                              }}
                            autoOk={true}
                            value={dateOfBirth}
                            onChange={(date: MaterialUiPickersDate) => {
                                setDateofBirth(date ? new Date(date.toString()) : null);
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} md={3} lg={3}>
                        <PhoneInput
                            inputClass="react-tel-input"
                            country={'th'}
                            value={phoneNumber}
                            onChange={phone => setPhoneNumber(phone)}
                            containerStyle={{
                                border: "none !important"
                              }}
                              inputStyle={{
                                border: 'none !important'
                              }}
                            enableSearch = {true}
                        />
                    </Grid> 

                    {/* <Grid item xs={12} md={3} lg={3}>
                        <TextField
                            label="Email"
                            value= { email }
                            onChange={validateEmail}
                            name="email"
                            fullWidth
                            margin="dense"
                            size="small"
                            style={{paddingRight: '30px' }}
                        />
                        {
                            emailErrorText ?
                            <span style={{color: 'red', fontSize: '12px'}}>{emailErrorText}</span>
                            :
                            <></>
                        }
                    </Grid>  */}

                </Grid>
                <Grid container style={{marginTop: '3%', marginLeft: '30px'}}>
                    
                    <Grid item xs={12}> 
                        <p style={{textAlign:'left', fontSize: 15}}> 
                            ขอแสดงความยินยอมโดยสมัครใจรับการรักษาในโรงพยาบาลนวมินทร์ 9
                        </p>
                    </Grid>
                    
                </Grid>

                <Grid container style={{marginTop: '3%'}}>
                    
                    <Grid item xs={12}> 
                        <p style={{textAlign:'left', fontSize: 15,marginLeft: '60px'}}> 
                            1. ข้าพเจ้ายินยอมโดยสมัครใจให้คณะแพทย์ -  พยาบาล และ/ หรือบุคลากรอื่นๆ ในทีมสุขภาพของโรงพยาบาลนวมินทร์9 
                            ทำการตรวจรักษาและกระทำการใดๆ ตามหลักวิชาชีพทางการแพทย์ได้ ตลอดระยะเวลาที่ผู้ป่วยเข้าอยู่รับการตรวจรักษา 
                            โดยจะต้องอธิบายข้อมูลรายละเอียดให้แก่ข้าพเจ้าได้เข้าใจและยินยอมก่อนที่จะดำเนินการ อีกทั้งเปิดโอกาสให้ข้าพเจ้าซักถามจนเป็นที่พอใจ

                        </p>
                    </Grid>    
                </Grid>

                <Grid container style={{marginTop: '3%'}}>
                    
                    <Grid item xs={12}> 
                        <p style={{textAlign:'left', fontSize: 15,marginLeft: '60px'}}> 
                            2. ข้าพเจ้าเข้าใจดีว่า ข้อมูลในการตรวจรักษาของข้าพเจ้าใช้เพื่อการตรวจวินิจฉัย และการรักษาของข้าพเจ้า ดังนั้น คณะแพทย์  - พยาบาล เจ้า
                            หน้าที่ และ/หรือบุคลากรอื่นๆ ในทีมสุขภาพของโรงพยาบาล ซึ่งผู้เกี่ยวข้องในการตรวจวินิจฉัย และการรักษาข้าพเจ้า 
                            มีความจำเป็นในการเข้าถึงข้อมูลของข้าพเจ้า รวมถึงการสื่อสารข้อมูลของข้าพเจ้าผ่านเครือข่ายสังคมออนไลน์ และบันทึกในระบบอิเล็กทรอนิกส์ เพื่อประโยชน์นการรักษา
                        </p>
                    </Grid>
                </Grid>

                <Grid container style={{marginTop: '3%'}}>  
                    <Grid item xs={12}> 
                        <p style={{textAlign:'left', fontSize: 15, marginLeft: '60px'}}> 
                            3. ในกรณีซึ่งค่าใช้จ่ายในการตรวจวินิจฉัย และการรักษาของข้าพเจ้า รับผิดชอบโดยบุคคลอื่น เช่น บริษัทต้นสังกัด และ หรือบริษัทประกัน 
                            เป็นต้น อาจมีการร้องขอให้โรงพยาบาลเปิดเผยข้อมูลของข้าพเจ้าแก่บริษัทดังกล่าว  ข้าพเจ้าเข้าใจและยินยอมให้โรงพยาบาลเปิดเผยข้อมูลทั้งหมดที่ขอของข้าพเจ้าให้บริษัทดังกล่าวได้
                        </p>
                    </Grid>
                </Grid>

                <br/>
                <br/>
                    <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>
                        ณ ที่นี้ข้าพเจ้าขอแสดงความ 
                        <FormControlLabel
                        style={{marginLeft: '30px'}}
                                control={
                                <Checkbox
                                    checked={agree}
                                    onChange={handleChangeAgree}
                                    name="agree"
                                    color="primary"
                                />
                                }
                                label="ยินยอม"
                        />

                        <FormControlLabel
                        style={{marginLeft: '30px', marginRight: '30px'}}
                                control={
                                <Checkbox
                                    checked={disagree}
                                    onChange={handleChangeDisagree}
                                    name="notAgree"
                                    color="primary"
                                />
                                }
                                label="ไม่ยินยอม"
                        />    

                        รักการรักษา  
                    </p>
                
                <br/>
                <br/>
                {
                    agree ? 
                    <Box
                    style={{
                        width: "100%",
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                        marginTop: '20px'
                    }}

                    onClick= {async()  => {
                        let inputdata = {
                            "platform": "web",
                            "name": name,
                            "lastname" : lastname,
                            "date_of_birth": dateStringAPI(dateOfBirth),
                            "age": parseInt(age),
                            "phone_no": "+" + phoneNumber,
                            "email": email,
                            "policy_id": policyId
                        }
                        try {
                            fire_getSecretCode(inputdata)
                              .then(
                                ({data}) => {
                                  if(data.data) {
                                    enqueueSnackbar("OTP code is sent to your phone number", {
                                      variant: "success",
                                      autoHideDuration: 3000,
                                      anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                      },
                                    });
                                    console.log("result otp response: ", data.data);
                                    let responsedata = data.data;
                                    setOtpResponseData({
                                      userName: responsedata.phone_on,
                                      ref_code: responsedata.ref_code,
                                      secret_code: responsedata.secret_code, 
                                      user_id: responsedata.user_id
                                    })
                                    setIsOtpModal(true);
                                  }
                              })
                              .catch((ex) => {
                                enqueueSnackbar(
                                    "Failed to get OTP code" +
                                      (ex ? " : " + ex.toString() : ""),
                                    {
                                      variant: "error",
                                      autoHideDuration: 3000,
                                      anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                      },
                                    }
                                  );        
                              });
                          } catch (ex) {}
                        
                    }}
                >
                    <Button  variant="contained" color="primary"> Submit</Button>
                </Box>
                :
                <Box
                    style={{
                        width: "100%",
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                        marginTop: '20px'
                    }}
                >
                    <Button  variant="contained"> Submit</Button>
                </Box>
                }
                
                <br/>
                
            </Box>
            <OtpModal 
              isOtpModal = {isOtpModal}
              setIsOtpModal = {setIsOtpModal}
              otpCode ={otpCode}
              setOtpCode = {setOtpCode}
              otpResponseData = {otpResponseData}
            />
          </Paper>
        </Container>
      </Box>
    );
  });
  