import { RequestSecretCode, VerifyOtpRequestData } from "../../../types/webconsentData";
import { CallTelemed } from "../../useCallAPI";

export const GetPolicy = () => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data : any;
      }>(
        {
          url:  "/v2/policy/telemed",
          method: "GET",
          params: { 
            "platform": "web",
            "accept-language": "th",
            "version": "1"
            } 
        }
      );
    
      return {
        result_getPolicy: res?.data ? res?.data : null,
        loading_getPolicy: loading,
        error_getPolicy: error
      };
}

export const GetSecretCode = () => {
  const [{ data: res, loading, error }, fire] = CallTelemed<{
    code: 0 | 1;
    message: string;
    data : any;
  }>(
    {
      url:  `/v2/policy/telemed/decide/agree`,
      method: "POST",
    },
    {
      manual: true,
    }
  );
  return {
    result_getSecretCode: res?.code === 1 ? res?.data : null,
    loading_getSecretCode: loading,
    error_getSecretCode: res?.code === 0 ? res?.message : null,
    fire_getSecretCode: (userInput: RequestSecretCode) => {
      return fire({
        data: userInput
      });
      // return new Promise((resolve, reject) => {
      //   fire({
      //     data: userInput,
      //   })
      //     .then(({ data: { data, code, message } }) => {
      //       if (code === 0) {
      //         reject(message);
      //       } else {
      //         resolve(data);
      //       }
      //     })
      //     .catch((ex) => {
      //       reject(ex.toString());
      //     });
      // });
    },
  };
};

// https://api-navamin9.space-trax.com/v2/policy/telemed/verify

export const VerifyOtp = () => {
  const [{ data: res, loading, error }, fire] = CallTelemed<{
    code: 0 | 1;
    message: string;
    data : any;
  }>(
    {
      url:  `/v2/policy/telemed/verify`,
      method: "POST",
    },
    {
      manual: true,
    }
  );
  return {
    result_verifyOTP: res?.code === 1 ? res?.data : null,
    loading_verifyOTP: loading,
    error_verifyotp: res?.code === 0 ? res?.message : null,
    fire_verifyOtp: (userInput: any) => {
      var formData = new FormData();
      formData.append('username', userInput.username.toString()); 
      formData.append('otp', userInput.otp.toString());
      formData.append('ref_code', userInput.ref_code.toString());
      formData.append('otp_type', userInput.otp_type.toString());
      formData.append('secret_code', userInput.secret_code.toString());
      formData.append('secret_type', userInput.secret_type.toString());
      formData.append('user_id', userInput.user_id.toString());
      return new Promise((resolve, reject) => {
        fire({
          data: formData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
    },
  };
};